/* contacts.js */

import React from 'react';

function Contacts() {
  return (
    <div>
      <h2>Contacts Page</h2>
    </div>
  );
}

export default Contacts;