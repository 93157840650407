/* App.js */

import './App.css';
import Navbar from "./components/Navbar/NavbarElements";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";


import Home from "./pages/home";
import Projects from "./pages/projects";
import Resume from "./pages/resume";
import Contacts from "./pages/contacts";
import NotFound from "./pages/notfound";


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/home' element={<Home />}/>
        <Route path='/projects' element={<Projects />}/>
        <Route path='/resume' element={<Resume />}/>
        <Route path='/contacts' element={<Contacts />}/>
        <Route path = '*' element={<NotFound />}/>
      </Routes>
    </Router>
  );
}

export default App;
