/* notfound.js */

import React from 'react';

function NotFound() {
  return (
    <div>
      <h2>Where do you think you're going?</h2>
      <h2>This page does not exist. Please go back to the desired path...</h2>
    </div>
  );
}

export default NotFound;