/* resume.js */

import React from 'react';

function Resume() {
  return (
    <div>
      <h2>Resume Page</h2>
    </div>
  );
}

export default Resume;