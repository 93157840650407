/* NavbarElements.js */

import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function NavbarElements() {
  return (
    <nav className='navbar'>
      <ul className='navbar-nav'>
        <li className='nav-item'>
            <Link to="/" className='nav-link'>About</Link></li>
        <li className='nav-item'>
            <Link to="/projects" className='nav-link'>Projects</Link></li>
        <li className='nav-item'>
            <Link to="/resume" className='nav-link'>Resume</Link></li>
        <li className='nav-item'>
            <Link to="/contacts" className='nav-link'>Contacts</Link></li>
      </ul>
    </nav>
  );
}

export default NavbarElements;