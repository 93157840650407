/* home.js */

import React from 'react';
import "./pagestyles.css";
//import profilePic from '../profilePic.png';

function Home() {
  return (
    <div>
      <div className='page-header'>
        <h1>Nothing of note quite yet.</h1>
      </div>
    </div>
  );
}

export default Home;