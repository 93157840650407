/* projects.js */

import React from 'react';

function Projects() {
  return (
    <div>
      <h2>Projects Page</h2>
    </div>
  );
}

export default Projects;